import { Injectable } from '@angular/core';


//Internal imports
import { environment } from "src/environments/environment";
import { CommonMethodService } from "src/app/shared/services/common-method.service";

declare const firebase: any;

@Injectable({
  providedIn: 'root'
})
export class WebNotificationsService {

  // Instance of the Firebase & Token
  public messaging;
  public notificationSupported = false;

  constructor(
    private commonMethodService: CommonMethodService
  ) {
  }

  public initPushNotificationsWeb() {
    return new Promise((resolve, reject) => {
      this.firebaseConfiguration().then((fb) => {
        resolve(fb);
      }).catch(error => {
        resolve({ status: false, message: '', error: error });
      });;
    })

  }

  // CONFIGURE FIREBASE
  public firebaseConfiguration() {
    return new Promise((resolve, reject) => {
      this.notificationSupported = firebase.messaging.isSupported();
      if (this.notificationSupported) {
        // CONFIGURING THE FIREBASE FCM Settings
        if (!firebase.apps.length) {
          firebase.initializeApp(environment.firebase);
        }
        this.messaging = firebase.messaging();
        if (this.messaging) {
          this.messaging.getToken().then((token) => {
            if (token) {
              console.log(token)
              localStorage.setItem('firebase_token', token);
              resolve({ status: true, message: 'FIREBASE_TOKEN_GENERATED', token: token });
            } else {
              resolve({ status: true, message: 'FIREBASE_TOKEN_BLOCKED' });
            }
          }, (error) => {
            resolve({ status: true, message: 'FIREBASE_TOKEN_BLOCKED' });
          });
          // EVENT WHICH WATCH THE MESSAGE RECIEVED IN FOREGROUND
          this.listenMessageToForegroundEvent();
        } else {
          resolve({ status: true, message: 'FIREBASE_NOT_INITIALIZED' });
        }
      } else {
        resolve({ status: true, message: 'FIREBASE_NOT_SUPPORTED' });
      }
    });
  }

  // EVENT WHICH WATCH THE MESSAGE RECIEVED IN FOREGROUND
  public listenMessageToForegroundEvent() {
    // EVENT WHICH WATCH THE MESSAGE RECIEVED IN FOREGROUND
    this.messaging.onMessage((payload: any) => {
      console.log("FIREBASE : Message received. ", payload);
      this.commonMethodService.renderNotification(payload);
    });
  }


  // FUN TO DELETE FIREBASE TOKEN
  public deleteToken() {
    if (this.messaging) {
      this.messaging.getToken().then(token => {
        if (token) {
          console.log("Deleting")
          this.messaging.deleteToken();
        }
      }).catch(err => {
        console.log("KK")
      });
    }
  }
}