// External Imports
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from  'rxjs';
//
import { CommonMethodService } from 'src/app/shared/services/common-method.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  // holds current displayed toasts
  // public currentToasts: Array<any> = [];

  // holds message type class
  // private typeClass: { success: string, error: string, info: string } = {
  //   success: 'bg-success text-light',
  //   error: 'bg-danger text-light',
  //   info: 'bg-info text-light'
  // }

  // default toast options for server error.
  private defaultOptions: { type: string, message: string, duration: number } = { type: "error", message: "Error while processing your request. Please try again later.", duration: 5000 };

  // emits value to toast conatiner when new toast added.
  public toastSubscription = new Subject();


  constructor(private toastr: ToastrService, private commonMethodService: CommonMethodService) { }


  public platform = this.commonMethodService.getPlatform();


  public showMessage(messageArg: { type: string, title?: string, message: string, duration?: number }): void {
    this.toastr.clear();
    let positionClass = 'toast-top-right';
    if (this.platform == 'android' || this.platform == 'ios') {
      positionClass = 'toast-ipad-mobile-bottom-center'
    }

    if (messageArg && Object.keys(messageArg).length) {
      if (messageArg.type === 'success') {
        this.toastr.success(messageArg.title || '', messageArg.message || this.defaultOptions.message, {
          timeOut: messageArg.duration || this.defaultOptions.duration, positionClass: positionClass,
        });
      } else if (messageArg.type === 'error') {
        this.toastr.error(messageArg.title || '', messageArg.message || this.defaultOptions.message, { timeOut: messageArg.duration || this.defaultOptions.duration, positionClass: positionClass });
      } else if (messageArg.type === 'warning') {
        this.toastr.warning(messageArg.title || '', messageArg.message || this.defaultOptions.message, { timeOut: messageArg.duration || this.defaultOptions.duration, positionClass: positionClass });
      } else if (messageArg.type === 'info') {
        this.toastr.info(messageArg.title || '', messageArg.message || this.defaultOptions.message, { timeOut: messageArg.duration || this.defaultOptions.duration, positionClass: positionClass });
      } else {
        this.toastr.error(messageArg.title || '', messageArg.message || this.defaultOptions.message, { timeOut: messageArg.duration || this.defaultOptions.duration, positionClass: positionClass });
      }
    }
  }

  // to hide toaster message
  public hideMessage(id: number): void {
    // this.currentToasts = this.currentToasts.filter(t => t.id !== id);
    // this.toastSubscription.next(this.currentToasts);
  }

  // to clear all toaster
  public clear(): void {
    // this.currentToasts = [];
    // this.toastSubscription.next(this.currentToasts);
  }
}
