
import { Injectable, Injector } from '@angular/core';
import { Capacitor } from '@capacitor/core';
// import { Device } from '@capacitor/device';
//
import { PostalChannelService } from "src/app/shared/services/postal.channel.service";
import { UserService } from './user.service';
import { MessageService } from './message.service';

@Injectable({
    providedIn: 'root'
})
export class CommonMethodService {

    constructor(
        private injector: Injector,
        private postalChannelService: PostalChannelService,
    ) { }

    getColumnWiseAllergyList(allergy) {
        try {
            allergy = allergy.sort((a, b) => a.name.localeCompare(b.name));
            allergy.forEach((element, index) => {
                if (element["isVisible"] == undefined) {
                    element.isVisible = true;
                    element.index = index + 1;
                }
            });
            allergy = allergy.filter(t => t.isVisible);
            //
            let list = [...allergy]
            let allergyList = [];
            if (list.length <= 5) {
                allergyList = [[...list]];
                return { allergyList: allergyList, allergy: allergy }
            } else if (allergy.length > 5 && allergy.length <= 14) {
                let middleIndex = Math.ceil(list.length / 2);
                const firstPart = list.splice(0, middleIndex);
                const secondPart = list.splice(-middleIndex);
                allergyList = [[...firstPart], [...secondPart]]
                return { allergyList: allergyList, allergy: allergy }
            } else if (allergy.length > 14) {
                let threePartIndex = Math.ceil(list.length / 3);
                const firstPart = list.splice(0, threePartIndex);
                const secondPart = list.splice(0, threePartIndex);
                const thirdPart = list.splice(-threePartIndex);
                allergyList = [[...firstPart], [...secondPart], [...thirdPart]]
                return { allergyList: allergyList, allergy: allergy }
            } else {
                return { allergyList: allergyList, allergy: allergy }
            }
        } catch (error) {
            return { allergyList: [[...allergy]], allergy: allergy }
        }
    }



    getFilteredMedicationList(medicationList) {
        try {
            medicationList = medicationList.sort((a, b) => a.medication_name.localeCompare(b.medication_name));
            medicationList.forEach((element, index) => {
                if (element["isVisible"] == undefined) {
                    element.isVisible = true;
                    element.index = index + 1;
                }
            });
            return medicationList;
        } catch (error) {
            return medicationList;
        }
    }

    //Return platform (web, ios, android)
    getPlatform() {
        return Capacitor.getPlatform();
    }

    //Return device Info
    // async getDeviceInfo() {
    //     const info = await Device.getInfo();
    //     const device_uuid = await Device.getId();
    //     return {
    //         model: info.model,
    //         operatingSystem: info.operatingSystem,
    //         osVersion: info.osVersion,
    //         uuid: device_uuid.identifier
    //     }
    // }

    //Publist Notification
    renderNotification(notificationObj: any) {
        if (notificationObj && notificationObj.data) {
            let notificationData = notificationObj.data;
            switch (notificationData._type) {
                case 'render_practice_form':
                    this.postalChannelService.PublishPostalEvent({
                        channel: notificationData._type,
                        topic: notificationData._type,
                        data: notificationData,
                    });
                    break;
                case 'render_practice_treatment_plan':
                    this.postalChannelService.PublishPostalEvent({
                        channel: notificationData._type,
                        topic: notificationData._type,
                        data: notificationData,
                    });
                    break;
                case 'formapp_device_disconnect':
                    this.removeDeviceManually();
                    break;
                default:
                    break;
            }
        }
    }

    public async removeDeviceManually() {
        const userService = this.injector.get(UserService);
        const messageService = this.injector.get(MessageService);
        try {
            await userService.logout();
            messageService.showMessage({ type: 'error', title: '', message: 'This device has been manually disconnected.' })
        } catch (err) {
            messageService.showMessage({ type: 'error', title: '', message: '' })
        }
    }

    // Pass location and app alias to check whether location is having specific alias app.
    checkLocationHasApp(location, appAlias) {
        if (location._id != "all") {
            let idx = (location.apps).findIndex((app) => { return app.alias == appAlias });
            if (idx == -1) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    convertImageToBase64(imgUrl) {
        return new Promise((resolve, reject) => {
            if (imgUrl && imgUrl.indexOf('http') > -1) {
                const image = new Image();
                image.crossOrigin = 'anonymous';
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.height = image.naturalHeight;
                    canvas.width = image.naturalWidth;
                    ctx.drawImage(image, 0, 0);
                    const dataUrl = canvas.toDataURL();
                    resolve(dataUrl);
                }
                image.onerror = (e) => {
                    resolve('');
                }
                image.src = imgUrl;
            } else {
                resolve(imgUrl)
            }
        })
    }
}