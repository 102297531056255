import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  // { path: '', redirectTo: '/patient-form/welcome', pathMatch: 'full' },

  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'practice-patient-form',
    loadChildren: () => import('./practice-patient-form/practice-patient-form.module').then(m => m.PracticePatientFormModule)
  },
  {
    path: 'treatment-plan',
    loadChildren: () => import('./treatment-plan-render/treatment-plan-render.module').then(m => m.TreatmentPlanRenderModule)
  },
  {
    path: 'patient-form',
    loadChildren: () => import('./patient-form/patient-form.module').then(m => m.PatientFormModule)
  },
  {
    path: '',
    loadChildren: () => import('./form-render/form-render.module').then(m => m.FormRenderModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
