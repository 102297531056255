import { EventEmitter, Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class PostalChannelService {
    // Postal message to be emitted through PostalChannel, and to subscribe its message
    public postalMessage$: EventEmitter<IPostal>;

    constructor() {
        // Initilize Postal Message
        this.postalMessage$ = new EventEmitter();
    }

    // Publish Postal Event, and pass message you want to broadcast
    public PublishPostalEvent(obj: IPostal): void {
        this.postalMessage$.emit(obj);
    }

    // To compare the coming message is the same where it is needed
    public CheckPostalEvent(obj: IPostal, compareTo: IPostal): boolean {
        // Compare Postal Message and return
        return Object.is(JSON.stringify(obj), JSON.stringify(compareTo));
    }
}
export interface IPostal {
    channel: string;
    topic: string;
    data?: any;
    envelope?: any;
}