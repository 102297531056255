import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { CommonApiService } from './common-api.service';
import { CommonMethodService } from './common-method.service';
import { PostalChannelService } from './postal.channel.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigurationService {

  private CURRENT_APPLICATION_DETAILS = {
    version: '',
    build: '',
    is_approved: false,
    force_update: false,
  };
  private LATEST_APPLICATION_DETAILS = {
    version: '',
    build: '',
    is_approved: false,
    force_update: false,
  };
  private APPLICATION_DATA;
  private IS_NEWER_VERSION_EXISTS: boolean = false;

  constructor(private commonApiService: CommonApiService, private commonMethodService: CommonMethodService, private postalChannelService: PostalChannelService) { }

  getLatestAppData(isBackground: boolean) {
    return new Promise((resolve, reject) => {
      this.commonApiService.getPromiseResponse({ apiName: `/patient-form/device/version-data`, methodType: 'get', parameterObject: {}, showLoading: !isBackground }, {}, !isBackground).then((result) => {
        App.getInfo().then((v) => {
          let platform = this.commonMethodService.getPlatform();
          if (result.status) {
            this.APPLICATION_DATA = result.data.data || {};
            if (platform === 'android' || platform === 'ios') {
              this.LATEST_APPLICATION_DETAILS = {
                version: result.data[platform].version,
                build: result.data[platform].build,
                is_approved: result.data[platform].is_approved || false,
                force_update: result.data[platform].force_update || false
              }
              this.CURRENT_APPLICATION_DETAILS = {
                version: v.version,
                build: v.build,
                is_approved: result.data[platform].is_approved || false,
                force_update: result.data[platform].force_update || false
              }
              let isNewerVersionAvailable = this.isNewerAndroidVersionExists;
              this.postalChannelService.PublishPostalEvent({
                channel: 'UPGRADE_APP_DATA_AVAILABLE',
                topic: 'UPGRADE_APP_DATA_AVAILABLE',
                data: { isNewerVersionAvailable: isNewerVersionAvailable, data: this.APPLICATION_DATA },
              });
              this.IS_NEWER_VERSION_EXISTS = isNewerVersionAvailable;
              resolve({ isNewerVersionAvailable: isNewerVersionAvailable, data: this.APPLICATION_DATA })
            } else {
              this.postalChannelService.PublishPostalEvent({
                channel: 'UPGRADE_APP_DATA_AVAILABLE',
                topic: 'UPGRADE_APP_DATA_AVAILABLE',
                data: { isNewerVersionAvailable: false, data: this.APPLICATION_DATA },
              });
              this.IS_NEWER_VERSION_EXISTS = false;
              resolve(false)
            }
          } else {
            this.IS_NEWER_VERSION_EXISTS = false;
            resolve(false)
          }
        }).catch(error => {
          this.IS_NEWER_VERSION_EXISTS = false;
          resolve(false)
        })
      }).catch(error => {
        this.IS_NEWER_VERSION_EXISTS = false;
        resolve(false)
      })
    });
  }

  private get isNewerAndroidVersionExists(): boolean {
    if (this.LATEST_APPLICATION_DETAILS.is_approved && this.LATEST_APPLICATION_DETAILS.force_update) {
      if (this.LATEST_APPLICATION_DETAILS.version && this.LATEST_APPLICATION_DETAILS.build && this.CURRENT_APPLICATION_DETAILS.version && this.CURRENT_APPLICATION_DETAILS.build) {
        if ((this.LATEST_APPLICATION_DETAILS.version !== this.CURRENT_APPLICATION_DETAILS.version) || (this.LATEST_APPLICATION_DETAILS.build !== this.CURRENT_APPLICATION_DETAILS.build)) {
          return true;
        }
      }
    }
    return false;
  }

  public GET_APP_DATA(key: string): any {
    if (this.APPLICATION_DATA && this.APPLICATION_DATA[key]) {
      return this.APPLICATION_DATA[key]
    } else {
      return null;
    }
  }

  public get isNewerVersionAvailable() {
    return this.IS_NEWER_VERSION_EXISTS;
  }
}
