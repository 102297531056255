import { Injectable } from '@angular/core';
import {
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
//
import { CommonMethodService } from "src/app/shared/services/common-method.service";


@Injectable({
  providedIn: 'root'
})
export class AppNotificationsService {

  constructor(
    private commonMethodService: CommonMethodService
  ) { }


  initPushNotificationsApp() {
    return new Promise((resolve, reject) => {
      // if (!firebase_token) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      if (this.commonMethodService.getPlatform() === 'android') {
        PushNotifications.checkPermissions().then((res: any) => {
          if (res.receive === 'granted') {
            this.registerNotificationAndroid().then((response) => {
              resolve(response)
            }).catch((error) => {
              resolve({ status: true, message: 'FIREBASE_TOKEN_ERROR', error: error });
            });
          } else if (res.receive === 'prompt') {
            PushNotifications.requestPermissions().then(result => {
              if (result.receive === 'granted') {
                this.registerNotificationAndroid().then((response) => {
                  resolve(response)
                }).catch((error) => {
                  resolve({ status: true, message: 'FIREBASE_TOKEN_ERROR', error: error });
                });
              } else {
                resolve({ status: true, message: 'FIREBASE_TOKEN_BLOCKED' });
              }
            }).catch((error) => {
              resolve({ status: true, message: 'FIREBASE_TOKEN_ERROR', error: error });
            });
          } else {
            resolve({ status: true, message: 'FIREBASE_TOKEN_BLOCKED' });
          }
        }).catch((error) => {
          resolve({ status: true, message: 'FIREBASE_TOKEN_ERROR', error: error });
        });
      } else if (this.commonMethodService.getPlatform() === 'ios') {
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            this.registerNotificationIOS().then((response) => {
              resolve(response)
            }).catch((error) => {
              resolve({ status: true, message: 'FIREBASE_TOKEN_ERROR', error: error });
            });
          } else {
            resolve({ status: true, message: 'FIREBASE_TOKEN_BLOCKED' });
          }
        }).catch((error) => {
          resolve({ status: true, message: 'FIREBASE_TOKEN_ERROR', error: error });
        });
      }
      this.listenMessageToForegroundEvent();
      // } else {
      //   this.listenMessageToForegroundEvent();
      //   resolve({ status: true, message: 'FIREBASE_TOKEN_GENERATED' });
      // }
    });

  }

  public registerNotificationAndroid() {
    return new Promise((resolve, reject) => {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register().then((res) => {
        //Create Token
        FCM.getToken()
          .then((r) => {
            localStorage.setItem('firebase_token', r.token);
            resolve({ status: true, message: 'FIREBASE_TOKEN_GENERATED', token: r.token });
          })
          .catch((error) => {
            resolve({ status: false, message: 'FIREBASE_TOKEN_ERROR', error: error });
          });
      }).catch((error) => {
        resolve({ status: false, message: 'FIREBASE_TOKEN_ERROR', error: error });
      });
    })
  }

  public registerNotificationIOS() {
    return new Promise((resolve, reject) => {
      PushNotifications.register().then((res: any) => {
        // alert('From Regisiter Promise : ' + JSON.stringify(res || {}))
      }).catch((error) => {

      })
      PushNotifications.addListener('registration', (token: Token) => {
        FCM.getToken().then((result) => {
          localStorage.setItem('firebase_token', result.token);
          resolve({ status: true, message: 'FIREBASE_TOKEN_GENERATED', token: result.token });
        }).catch((error) => {
          resolve({ status: false, message: 'FIREBASE_TOKEN_ERROR', error: error });
        });
      })
      PushNotifications.addListener('registrationError', error => {
        resolve({ status: false, message: 'FIREBASE_TOKEN_ERROR', error: error });
      });
    })

    // return new Promise((resolve, reject) => {
    //   // Register with Apple / Google to receive push via APNS/FCM
    //   PushNotifications.register().then((res) => {
    //     //Create Token
    //     FCM.getToken()
    //       .then((r) => {
    //         localStorage.setItem('firebase_token', r.token);
    //         resolve({ status: true, message: 'FIREBASE_TOKEN_GENERATED' });
    //       })
    //       .catch((error) => {
    //         resolve({ status: false, message: 'FIREBASE_TOKEN_ERROR', error: error });
    //       });
    //   }).catch((error) => {
    //     resolve({ status: false, message: 'FIREBASE_TOKEN_ERROR', error: error });
    //   });
    // })
  }


  public listenMessageToForegroundEvent() {
    //Listen forground event
    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      // alert("pushNotificationReceived =>> " + JSON.stringify(notification))
      let platform = this.commonMethodService.getPlatform();
      if (platform == 'android' || platform == 'ios') {  //Remove forground notification
        PushNotifications.getDeliveredNotifications().then((x) => {
          PushNotifications.removeDeliveredNotifications(x).then(() => { }).catch((error) => { });
        }).catch((error) => {

        });
      }
      //Render form on forground notification
      this.commonMethodService.renderNotification(notification);
    });
    //Lister background event
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      // alert("pushNotificationActionPerformed =>> " + JSON.stringify(notification))
      this.commonMethodService.renderNotification(notification.notification);
    });
  }

  public deleteToken() {
    // Remove FCM instance
    PushNotifications.unregister();
    FCM.deleteInstance()
      .then(() => { })
      .catch((err) => console.log(err));
  }

}
