// External Imports
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, tap } from "rxjs";
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class InterceptedHttp implements HttpInterceptor {

    constructor(private userService: UserService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = localStorage.getItem('secretToken');
        let ip_address = localStorage.getItem('ip_address');
        // if (!req.headers.has('Content-Type')) {
        //     req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        // }
        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: token,
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check = 0, pre - check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'X-PUBLIC-IP': ip_address,
                    'X-SOURCE': 'formapp'
                }
            });
        }
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (event.body instanceof Object) {
                        switch (event.body.code) {
                            case 13:
                                this.userService.logout();
                                return false
                            default:
                                break;
                        }
                    }
                }
            }, error => {
                console.log("interceptior error::::::::", error.message);
            })
        )
    }
}