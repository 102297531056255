// External Imports
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
//Internal Import
import { CommonApiService } from './common-api.service';
import { NotificationsService } from './notifications/notifications.service ';
import { MessageService } from './message.service';
import { NchanConnectionService } from './nchan-connection.service';
import { ApplicationConfigurationService } from './application-configuration.service';
import { PracticePatientFormService } from "src/app/practice-patient-form/practice-patient-form.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _isAuthenticated: boolean = false;
    private _userDetails: any = {};
    private _selectedLocation: string;
    private _deviceDetails: { deviceId: string, deviceName: string } = { deviceId: '', deviceName: '' };
    private _lastUserPath: string = '/';

    constructor(
        private commonApiService: CommonApiService,
        private router: Router,
        private notificationsService: NotificationsService,
        private messageService: MessageService,
        private nchanService: NchanConnectionService,
        private applicationConfigurationService: ApplicationConfigurationService,
        private injector: Injector
    ) { }


    public get userDetail(): any {
        return this._userDetails;
    }

    public set userDetail(userData: any) {
        this._userDetails = userData;
    }

    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    public set isAuthenticated(status: boolean) {
        this._isAuthenticated = status;
    }


    /**
     * Set/Unset UserDetail Variables
     * @param userDetail This set/unset userdetails of login user in the browser
     */
    public setUserAuthentication(userDetail: any) {
        return new Promise((resolve, reject) => {
            if (userDetail.status && userDetail.data && userDetail.data._id) {
                localStorage.setItem('userId', userDetail.data._id);
                if (userDetail.token) {
                    localStorage.setItem('secretToken', userDetail.token);
                }
                if (!userDetail.data.org_name) {
                    userDetail.data.org_name = userDetail.data.organizations[0].name
                }
                if (!userDetail.data.org_id) {
                    userDetail.data.org_id = userDetail.data.organizations[0].id
                }
                this.userDetail = userDetail.data;
                this.isAuthenticated = true;
                this.connectSocket();
                this.notificationsService.initializePushNotification().then((result: any) => {
                    this.notificationsService.updateNotificationToken(this.userDetail.device_Id, result);
                    resolve(result);
                }).catch(error => {
                    resolve({ status: false, message: '', error: error });
                });
            } else {
                this._isAuthenticated = false;
                this.userDetail = {};
                localStorage.removeItem('userId');
                localStorage.removeItem('secretToken');
                localStorage.removeItem('firebase_token');
                localStorage.removeItem('form_device_update_details');
                this.notificationsService.removeNotificationToken();
                this.disconnectSocket();
                resolve({ status: false });
            }
        });
    }

    /**
     * 
     */
    getSessionDetails() {
        return new Promise((resolve, reject) => {
            this.applicationConfigurationService.getLatestAppData(false).then((appDataResult: any) => {
                let token = localStorage.getItem('secretToken');
                let userId = localStorage.getItem('userId');
                if (token && userId) {
                    this.commonApiService.getPromiseResponse({ apiName: `/notification/checklogin`, methodType: 'post', parameterObject: {} }, undefined, false).then(userData => {
                        if (userData.status && userData.data && userData.data._id) {
                            this.setUserAuthentication(userData).then((result) => {
                                resolve(userData.status)
                            }).catch((err) => {
                                resolve(userData.status)
                            })
                        } else {
                            if (userData.code === 13) {
                                this.logout().then((logOutRes) => {
                                    resolve(logOutRes)
                                }).catch(err => {
                                    resolve(false)
                                })
                            } else {
                                resolve(false)
                            }
                        }
                    }).catch(error => {
                        resolve(false)
                    })
                } else {
                    this.setUserAuthentication({}).then((result) => {
                        resolve(true)
                    }).catch((err) => {
                        resolve(false)
                    })
                }
            });
        })
    }

    /**
     * Log out from device
     */
    logout(): any {
        return new Promise((resolve, reject) => {
            // const practicePatientFormService = this.injector.get(PracticePatientFormService);
            // practicePatientFormService.updateKioskModeStatus(false);


            this.deleteDevice().then((isDeviceDeleted: any) => {
                if (isDeviceDeleted) {
                    this.commonApiService.getPromiseResponse({ apiName: `/auth/logout`, methodType: 'post', parameterObject: {}, showLoading: true }).then(resp => {
                        this.setUserAuthentication({});
                        if (this.router.url !== '/auth/login') {
                            this.router.navigate(['/auth/login'], { replaceUrl: true });
                        }
                        resolve(true);
                    }).catch(err => {
                        this.messageService.showMessage({ type: 'error', message: 'Error occured while logging out from the system. Please try again later.' })
                        resolve(false);
                    })
                } else {
                    this.messageService.showMessage({ type: 'error', message: 'Error occured while deleting the device. Please try again later.' })
                    resolve(false);
                }
            }).catch(err => {
                this.messageService.showMessage({ type: 'error', message: '' });
                resolve(false);
            });
        });
    }
    /**
    * Deleted device after logout
    */
    private deleteDevice() {
        return new Promise((resolve, reject) => {
            if (this.userDetail.device_Id) {
                this.commonApiService.getPromiseResponse({
                    apiName: `/patient-form/device/update-kiosk-status`,
                    methodType: 'post',
                    parameterObject: {
                        is_kiosk_mode_on: false,
                        deviceId: this.userDetail.device_Id
                    }
                }).then(resp => {
                    this.commonApiService.getPromiseResponse({
                        apiName: `/patient-form/device/remove`,
                        methodType: 'post',
                        parameterObject: { locationId: this.userDetail.selected_location, deviceId: this.userDetail.device_Id },
                        showLoading: true
                    }).then(resp => {
                        if (resp.status) {
                            resolve(true);
                        } else {
                            resolve(true);
                        }
                    }).catch(error => {
                        resolve(true);
                    })
                })
            } else {
                resolve(true);
            }
        })
    }

    private connectSocket() {
        console.log(this.userDetail);
        this.nchanService.connect(this.userDetail.org_id)
    }

    private disconnectSocket() {
        console.log(this.userDetail);
        this.nchanService.disconnect()
    }
}