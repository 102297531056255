import { Injectable } from "@angular/core";
//
import { CommonApiService } from "src/app/shared/services/common-api.service";
import { UserService } from 'src/app/shared/services/user.service';
import { PostalChannelService } from "src/app/shared/services/postal.channel.service";
import { PushNotifications } from "@capacitor/push-notifications";
import { NotificationsService } from "../shared/services/notifications/notifications.service ";

@Injectable({ providedIn: 'root' })
export class PracticePatientFormService {
  private _formDeviceRequestDetails: any;
  private _preferenceDetails: any;
  private _patientCheckInDetails: any;
  private _themeColor: any;
  public _pfAppoinmentArrivalSetting: any;
  public _preserveFormDeviceRequestDetails: any;
  public is_kiosk_mode_on = false;


  constructor(
    private commonApiService: CommonApiService,
    private userService: UserService,
    private postalChannelService: PostalChannelService,
    private notificationsService: NotificationsService) { }

  public get formDeviceRequestDetails(): any {
    return this._formDeviceRequestDetails;
  }

  public set formDeviceRequestDetails(requestDetails: any) {
    this._formDeviceRequestDetails = requestDetails;
  }

  public get preserveFormDeviceRequestDetails(): any {
    return this._preserveFormDeviceRequestDetails;
  }

  public set preserveFormDeviceRequestDetails(requestDetails: any) {
    this._preserveFormDeviceRequestDetails = requestDetails;
  }

  getDeviceDetail(isLastFormSubmitted = false) {
    this.formDeviceRequestDetails = undefined;
    return new Promise((resolve, reject) => {
      let userDetail = this.userService.userDetail;
      this.commonApiService.getPromiseResponse({ apiName: `/patient-form/device/get?device_Id=${userDetail.device_Id}&is_preference=true`, methodType: 'get', parameterObject: {}, showLoading: true }, {}, true).then(deviceDetail => {
        if (deviceDetail.status && deviceDetail && deviceDetail.data) {
          this.formDeviceRequestDetails = deviceDetail.data;
          if(!isLastFormSubmitted){
            this._preserveFormDeviceRequestDetails = deviceDetail.data.request_list;
          }
          this._preferenceDetails = deviceDetail.preferenceDetails;
          this._pfAppoinmentArrivalSetting = deviceDetail.pfAppoinmentArrivalSetting ? deviceDetail.pfAppoinmentArrivalSetting : null;
          if (deviceDetail.data.last_request_updated_at || deviceDetail.data.last_setting_updated_at) {
            localStorage.setItem('form_device_update_details', JSON.stringify({ last_request_updated_at: deviceDetail.data.last_request_updated_at, last_setting_updated_at: deviceDetail.data.last_setting_updated_at }));
          } else {
            localStorage.removeItem('form_device_update_details');
          }
          if(!isLastFormSubmitted && this.formDeviceRequestDetails.request_list.length > 0){
            this.postalChannelService.PublishPostalEvent({
              channel: 'form_request_details_fetched',
              topic: 'form_request_details_fetched',
              data: true,
            });
          }
          resolve(deviceDetail)
        } else {
          this.userService.logout();
        }
      }).catch(error => {
        reject(error);
      })
    })
  }

  public get isFormRequested(): boolean {
    return (this.formDeviceRequestDetails &&
      this.formDeviceRequestDetails.request_list &&
      this.formDeviceRequestDetails.request_list.length > 0 && this.formDeviceRequestDetails.request_list[0].form_id);
  }

  public get isTreatmentPlanRequested(): string {
    if (this.formDeviceRequestDetails &&
      this.formDeviceRequestDetails.request_list &&
      this.formDeviceRequestDetails.request_list.length > 0 && this.formDeviceRequestDetails.request_list[0].treatment_plan_id) {
      return this.formDeviceRequestDetails.request_list[0].treatment_plan_id;
    }
    return "";
  }

  public get preferenceDetails(): object {
    return this._preferenceDetails;
  }

  public get isPreservedFormRequested(): boolean {
    return (this.preserveFormDeviceRequestDetails &&
      this.preserveFormDeviceRequestDetails.length > 0 && this.preserveFormDeviceRequestDetails[0].form_id);
  }

  /**
  * Summary: This method is used to update device status to online.
  * @param parameterObject 
  */
  updateDeviceStatus(parameterObject) {
    this.commonApiService.getPromiseResponse({
      apiName: `/patient-form/device/update-status`,
      methodType: 'post',
      parameterObject: parameterObject
    }).then(resp => {
      // Do something
    }).catch(error => {
      // Do something
    });
  }

  checkToUpdateDataInBackground(payloadData: any) {
    let formDetails: any = localStorage.getItem('form_device_update_details') || '{}';
    if (payloadData.last_request_updated_at || payloadData.last_setting_updated_at) {
      try {
        formDetails = JSON.parse(formDetails);
        if ((formDetails.last_request_updated_at && payloadData.last_request_updated_at && payloadData.last_request_updated_at > formDetails.last_request_updated_at) || (!formDetails.last_request_updated_at && payloadData.last_request_updated_at)) {
          return { update: true, type: 'request' };
        } else if ((formDetails.last_setting_updated_at && payloadData.last_setting_updated_at && payloadData.last_setting_updated_at > formDetails.last_setting_updated_at) || (!formDetails.last_setting_updated_at && payloadData.last_setting_updated_at)) {
          return { update: true, type: 'settings' };
        } else {
          return { update: false }
        }
      } catch (e) {
        return { update: false }
      }
    }
    return { update: false }
  }

  checkForTheRefreshMobileIpadApp(stateOfAppIsActive: boolean) {
    if (stateOfAppIsActive) {
      PushNotifications.getDeliveredNotifications().then((notificationResult) => {
        if (notificationResult && notificationResult.notifications && notificationResult.notifications.length) {
          this.postalChannelService.PublishPostalEvent({
            channel: 'APPICATION_STATE_CHANGE',
            topic: 'APPICATION_STATE_CHANGE',
            data: { isActive: stateOfAppIsActive },
          });
        } else {
          if (this.userService.isAuthenticated && this.userService.userDetail.device_Id) {
            this.backgroundCheck(stateOfAppIsActive);
          }
        }
        PushNotifications.removeAllDeliveredNotifications().then(() => { }).catch((error) => { });
      }).catch(err => {
        if (this.userService.isAuthenticated && this.userService.userDetail.device_Id) {
          this.backgroundCheck(stateOfAppIsActive);
        }
        PushNotifications.removeAllDeliveredNotifications().then(() => { }).catch((error) => { });
      })
    }
  }

  public backgroundCheck(stateOfAppIsActive) {
    this.notificationsService.initializePushNotification().then((result: any) => {
      this.notificationsService.updateNotificationToken(this.userService.userDetail.device_Id, result);
    }).catch(error => { });
    this.commonApiService.getPromiseResponse({ apiName: `/patient-form/device/check-background-update`, methodType: 'get', parameterObject: {}, showLoading: false }, undefined, false).then(res => {
      if (res.status) {
        this.checkDeviceStatus(res, stateOfAppIsActive, false);
      } else {
        this.userService.logout();
      }
    }).catch(error => {

    })
  }

  updateRequestExpiry() {
    this.commonApiService.getPromiseResponse({ apiName: `/patient-form/device/update-request-expiry`, methodType: 'post', parameterObject: {}, showLoading: false }, {}, false).then(() => {
    }).catch(error => {
    })
  }


  checkDeviceStatus(res, stateOfAppIsActive, isForAuthenticate = false) {
    switch (res.error) {
      case 'REQUEST_NOT_FOUND':
        localStorage.removeItem('form_device_update_details');
        if (this.isFormRequested || this.isTreatmentPlanRequested) {
          this.postalChannelService.PublishPostalEvent({
            channel: 'APPICATION_STATE_CHANGE',
            topic: 'APPICATION_STATE_CHANGE',
            data: { isActive: stateOfAppIsActive },
          });
        }
        break;
      case 'DEVICE_NOT_FOUND':
        localStorage.removeItem('form_device_update_details');
        this.userService.logout();
        break;
      case 'NOT_AUTHENTICATED':
        localStorage.removeItem('form_device_update_details');
        this.userService.logout();
        break;
      default:
        if (isForAuthenticate) {
          return true;
        } else {
          let checkResult: any = this.checkToUpdateDataInBackground(res.data);
          if (checkResult.update) {
            this.postalChannelService.PublishPostalEvent({
              channel: 'APPICATION_STATE_CHANGE',
              topic: 'APPICATION_STATE_CHANGE',
              data: { isActive: stateOfAppIsActive },
            });
          }
          localStorage.setItem('form_device_update_details', JSON.stringify({ last_request_updated_at: res.data.last_request_updated_at, last_setting_updated_at: res.data.last_setting_updated_at }));
        }
        break;
    }
  }
  
  setPatientCheckInDetails(checkInDetails){
    this.patientCheckInDetails = checkInDetails;
  }

  public set patientCheckInDetails(checkInDetails: any) {
    this._patientCheckInDetails = checkInDetails;
  }

  public get patientCheckInDetails(): any {
    return this._patientCheckInDetails;
  }

  setFormsThemeColor(themeColor){
    this.themeColor = themeColor;
  }

  public set themeColor(themeColor: any) {
    this._themeColor = themeColor;
  }

  public get themeColor(): any {
    return this._themeColor;
  }


  /**
  * Summary: This method is used to update kiosk mode status.
  */
  updateKioskModeStatus(is_kiosk_mode_on) {
    this.is_kiosk_mode_on = is_kiosk_mode_on;
    let parameterObject = {
      is_kiosk_mode_on: is_kiosk_mode_on,
      deviceId: this.userService.userDetail.device_Id
    }
    this.commonApiService.getPromiseResponse({
      apiName: `/patient-form/device/update-kiosk-status`,
      methodType: 'post',
      parameterObject: parameterObject
    }).then(resp => {
    }).catch(error => {
      console.log(error)
    });

  }

}