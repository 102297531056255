import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { SafeArea } from 'capacitor-plugin-safe-area';

import { environment } from 'src/environments/environment';
import { CommonMethodService } from 'src/app/shared/services/common-method.service';
import { PostalChannelService } from "src/app/shared/services/postal.channel.service";
import { PushNotifications } from '@capacitor/push-notifications';
import { PracticePatientFormService } from './practice-patient-form/practice-patient-form.service';
import { ApplicationConfigurationService } from './shared/services/application-configuration.service';
import { NchanReleaseConnectionService } from './shared/services/nchan-connection.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private safeAreaEventListner;
  private networkStatusChangeEventListener;
  private appStatChangeEventListener;
  private backButonEventListener;
  private postalMessageSubscription;

  public isInternetConnected: boolean = true;
  public platform: string;
  public isNewerVersionOfAppAvailable = false;


  constructor(
    private commonMethodService: CommonMethodService,
    private router: Router,
    private practicePatientFormService: PracticePatientFormService,
    private cdr: ChangeDetectorRef,
    private applicationConfigurationService: ApplicationConfigurationService,
private postalChannelService: PostalChannelService,
    private ngZone: NgZone,
    private nchanReleaseConnectionService: NchanReleaseConnectionService
  ) { }

  ngOnInit() {
    this.platform = this.commonMethodService.getPlatform();
    if (this.platform === 'android' || this.platform === 'ios') {
      this.backButtonHandler();
      this.manageSafeArea(); // Manage Safe Area for IOS and Android
      this.getDeviceNetworkStatus(); // Manage Network Status of Device
      this.getDeviceState(); // Maintains the State of the Application
      this.updateAppDataAndVersion(); // GET STATIC DATA AND APP VERSION
      this.nchanReleaseConnectionService.connect();
      this.postalMessageSubscription = this.postalChannelService.postalMessage$.subscribe(postalObj => {
        if (postalObj.channel == 'UPGRADE_APP_DATA_AVAILABLE') {
          this.updateAppDataAndVersion();
        }
      })
    } else {
      this.setLocalIpAddress();
    }
  }

  checkIsDesktopInstall() {
    let openInBrowser = localStorage.getItem('open-in-browser');
    if (!window.matchMedia('(display-mode: fullscreen)').matches && !openInBrowser) {
      let isDesktopAppInstalled = localStorage.getItem('desktop');
      if (isDesktopAppInstalled) {
        if (window.location.pathname.indexOf('desktop') === -1) {
          this.router.navigate(['desktop', 'link'], { queryParams: { url: window.location.pathname } })
        }
      } else {
        window.addEventListener('appinstalled', (e) => {
          localStorage.setItem('desktop', 'true');
        })
      }
    }
  }

  private manageSafeArea() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      for (const [key, value] of Object.entries(insets)) {
        if (value) {
          document.documentElement.style.setProperty(
            `--safe-area-${key}`,
            `${value}px`,
          );
        }
      }
    });
    // when safe-area changed
    if (this.safeAreaEventListner) {
      this.safeAreaEventListner.remove();
    }
    this.safeAreaEventListner = SafeArea.addListener('safeAreaChanged', data => {
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        if (value) {
          document.documentElement.style.setProperty(
            `--safe-area-${key}`,
            `${value}px`,
          );
        }
      }
    });
  }

  async setLocalIpAddress() {
    const response = await fetch(environment.ipinfoUrl);
    const result: any = await response.json(); //extract JSON from the http response
    if (result.ip) {
      localStorage.setItem('ip_address', result.ip);
    }
  }

  private getDeviceNetworkStatus() {
    if (this.networkStatusChangeEventListener) {
      this.networkStatusChangeEventListener.remove();
    }
    this.networkStatusChangeEventListener = Network.addListener('networkStatusChange', status => {
      if (this.isInternetConnected !== status.connected) {
        if (status.connected) {
          if (this.platform === 'android') {
            location.reload();
          } else if (this.platform === 'ios') {
            location.reload();
          }
        } else {
          this.isInternetConnected = status.connected || false;
          this.cdr.detectChanges();
        }
      }
    });

    Network.getStatus().then(status => {
      if (this.isInternetConnected !== status.connected) {
        this.isInternetConnected = status.connected || false;
        this.cdr.detectChanges();
      }
    });
  }

  private getDeviceState() {
    PushNotifications.removeAllDeliveredNotifications().then(() => { }).catch((error) => { });
    if (this.appStatChangeEventListener) {
      this.appStatChangeEventListener.remove();
    }
    this.appStatChangeEventListener = App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.ngZone.run(() => {
          this.checkForUpgradeVersion();
        })
      }
      this.practicePatientFormService.checkForTheRefreshMobileIpadApp(isActive);
    });
  }

  backButtonHandler() {
    if (this.backButonEventListener) {
      this.backButonEventListener.remove();
    }
    this.backButonEventListener = App.addListener('backButton', (data) => {
      App.exitApp();
    });
  }

  private updateAppDataAndVersion() {
    this.isNewerVersionOfAppAvailable = false;
    if (this.applicationConfigurationService.isNewerVersionAvailable) {
      this.isNewerVersionOfAppAvailable = true
    }
    this.cdr.detectChanges();
  }

  checkForUpgradeVersion() {
    this.applicationConfigurationService.getLatestAppData(true).then((appDataResult: any) => {
      this.updateAppDataAndVersion();
    });
  }

  ngOnDestroy() {
    if (this.postalMessageSubscription) {
      this.postalMessageSubscription.unsubscribe();
    }
    if (this.platform === 'android' || this.platform === 'ios') {
      if (this.safeAreaEventListner) {
        this.safeAreaEventListner.remove();
      }
      if (this.backButonEventListener) {
        this.backButonEventListener.remove();
      }
      if (this.networkStatusChangeEventListener) {
        this.networkStatusChangeEventListener.remove();
      }
      if (this.appStatChangeEventListener) {
        this.appStatChangeEventListener.remove();
      }
      this.nchanReleaseConnectionService.disconnect();
    }
  }
}
