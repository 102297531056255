import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService, LoaderState } from './loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss']
})

export class LoaderComponent implements OnInit {
    public show: boolean = false;
    private subscription: Subscription;

    constructor(private loaderService: LoaderService,
        private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        const self = this;
        self.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
            self.show = state.show;
            self.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}