import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonMethodService } from './common-method.service';
import { UserService } from './user.service';
import { PostalChannelService } from './postal.channel.service';
import { ApplicationConfigurationService } from './application-configuration.service';

@Injectable({
    providedIn: 'root'
})
export class NchanConnectionService {
    public subscribeURL: any = "";
    private nchan: WebSocket;

    constructor(private injector: Injector, private commonMethodService: CommonMethodService, private postalChannelService: PostalChannelService, private applicationConfigurationService: ApplicationConfigurationService) { }

    public connect(organizationId: string) {
        console.log("Socket Connect")
        this.subscribeURL = `${environment.socketUrlNchan}sub/${organizationId}/adit_patientform`;
        if (!this.nchan) {
            this.nchan = new WebSocket(this.subscribeURL);
            this.listenNChanCloseEvent();
            this.listenNChanMessageEvent();
        }
    }

    private listenNChanCloseEvent() {
        if (this.nchan) {
            this.nchan.onclose = (event) => {
                if (!event.wasClean) {
                    console.log('Nchan socket closed. Attempting to reconnect...', event);
                    // Reconnection Code goes Here
                    this.handleDisconnect(event);
                }
            };
        }
    }

    private listenNChanMessageEvent() {
        if (this.nchan) {
            this.nchan.addEventListener('message', event => {
                console.log(event);
                let data = JSON.parse(event.data);
                if (data && data.type) {
                    if (this.checkEventAuthentication(data.data)) {
                        let emittedData = JSON.parse(JSON.stringify(data.data));
                        switch (data.type) {
                            case "formapp_device_disconnect":
                                this.commonMethodService.removeDeviceManually();
                                break;
                            case "render_practice_form":
                                this.postalChannelService.PublishPostalEvent({
                                    channel: data.type,
                                    topic: data.type,
                                    data: emittedData,
                                });
                                break;
                            case "render_practice_treatment_plan":
                                this.postalChannelService.PublishPostalEvent({
                                    channel: data.type,
                                    topic: data.type,
                                    data: emittedData,
                                });
                                break;
                            case "requested_form_updated":
                                this.postalChannelService.PublishPostalEvent({
                                    channel: data.type,
                                    topic: data.type,
                                    data: emittedData,
                                });
                                break;
                            default:
                                break;
                        }
                    } else {
                        // const userService = this.injector.get(UserService);
                        // if (!userService.userDetail.org_id) {
                            let emittedData = JSON.parse(JSON.stringify(data.data));
                            switch (data.type) {
                                case "requested_form_updated":
                                    this.postalChannelService.PublishPostalEvent({
                                        channel: data.type,
                                        topic: data.type,
                                        data: emittedData,
                                    });
                                    break;
                                default:
                                    break;
                            }
                        // }
                    }
                }
            })
        }
    }

    public disconnect() {
        if (this.nchan) {
            console.log("nchan connection service disconnect", this.nchan);
            this.nchan.close();
            this.nchan = null;
        }
    }

    private handleDisconnect(event: CloseEvent) {
        if (event.code === 1000) {
            console.log('WebSocket closed by client');
            return;
        }
        console.log('WebSocket connection lost, attempting to reconnect...');
        setTimeout(() => {
            const userService = this.injector.get(UserService);
            if (userService.userDetail && userService.userDetail.org_id) {
                this.connect(userService.userDetail.org_id);
            }
        }, 1000); // Try reconnecting after 1 second (adjust as needed)
    }

    checkEventAuthentication(emittedData: any) {
        const userService = this.injector.get(UserService);
        return userService.userDetail.org_id === emittedData.organizationId && userService.userDetail.device_Id === emittedData.deviceId
    }
}


@Injectable({
    providedIn: 'root'
})
export class NchanReleaseConnectionService {
    public subscribeReleaseURL: any = "";
    private nchanRelease: WebSocket;

    constructor(private applicationConfigurationService: ApplicationConfigurationService) { }

    public connect() {
        this.subscribeReleaseURL = `${environment.socketUrlNchan}sub/adit_patientform_release`;
        if (!this.nchanRelease) {
            this.nchanRelease = new WebSocket(this.subscribeReleaseURL);
            this.listenNChanCloseEvent();
            this.listenNChanMessageEvent();
        }
    }

    private listenNChanCloseEvent() {
        if (this.nchanRelease) {
            this.nchanRelease.onclose = (event) => {
                if (!event.wasClean) {
                    console.log('Nchan socket closed. Attempting to reconnect...', event);
                    // Reconnection Code goes Here
                    this.handleDisconnect(event);
                }
            };
        }
    }

    private listenNChanMessageEvent() {
        if (this.nchanRelease) {
            this.nchanRelease.addEventListener('message', event => {
                let data = JSON.parse(event.data);
                if (data && data.type) {
                    // let emittedData = JSON.parse(JSON.stringify(data.data));
                    switch (data.type) {
                        case "UPGRADE_APP_DATA_AVAILABLE":
                            this.applicationConfigurationService.getLatestAppData(true);
                            break;
                        default:
                            break;
                    }
                }
            })
        }
    }

    public disconnect() {
        if (this.nchanRelease) {
            console.log("nchan connection service disconnect", this.nchanRelease);
            this.nchanRelease.close();
            this.nchanRelease = null;
        }
    }

    private handleDisconnect(event: CloseEvent) {
        if (event.code === 1000) {
            console.log('WebSocket closed by client');
            return;
        }
        console.log('WebSocket connection lost, attempting to reconnect...');
        setTimeout(() => {
            this.connect();
        }, 1000); // Try reconnecting after 1 second (adjust as needed)
    }
}