import { Injectable } from '@angular/core';

//Internal imports
import { AppNotificationsService } from 'src/app/shared/services/notifications/app-notifications.service';
import { WebNotificationsService } from 'src/app/shared/services/notifications/web-notifications.service';
import { CommonMethodService } from 'src/app/shared/services/common-method.service';
import { CommonApiService } from '../common-api.service';



@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    public firebaseDetails: any = {};

    constructor(
        private webNotificationsService: WebNotificationsService,
        private appNotificationsService: AppNotificationsService,
        private commonMethodService: CommonMethodService,
        private commonApiService: CommonApiService
    ) { }


    initializePushNotification() {
        return new Promise((resolve, reject) => {
            //Push notification setup for web/android/ios
            let platform = this.commonMethodService.getPlatform();
            switch (platform) {
                case 'web':
                    //Firease Setup for web
                    this.webNotificationsService.initPushNotificationsWeb().then(result => {
                        resolve(result);
                    }).catch(error => {
                        resolve({ status: false, message: '', error: error });
                    });
                    break;
                case 'android':
                case 'ios':
                    this.appNotificationsService.initPushNotificationsApp().then(result => {
                        console.log(result)
                        resolve(result);
                    }).catch(error => {
                        resolve({ status: true, message: '', error: error });
                    });
                    break;
                default:
                    resolve({ status: false, message: '' })
                    break;
            }
        });
    }

    updateNotificationToken(deviceId: string, firebaseConfiguration: any) {
        let params = { deviceId: deviceId, status: firebaseConfiguration.message || '', token: firebaseConfiguration.token || '' };
        this.firebaseDetails = params;
        if (deviceId) {
            this.commonApiService.getPromiseResponse({ apiName: '/patient-form/device/token', methodType: 'post', parameterObject: params, showLoading: false }, undefined, false).then(() => {

            }).catch(err => {

            });
        }
    }

    // Unregister firebase token
    removeNotificationToken() {
        let platform = this.commonMethodService.getPlatform();
        switch (platform) {
            case 'web':
                this.webNotificationsService.deleteToken();
                break;
            case 'android':
            case 'ios':
                this.appNotificationsService.deleteToken();
                break;
            default:
                break;
        }
    }
}
