/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';
// import { enableProdMode } from '@angular/core';
// import { environment } from './environments/environment';


// Call the element loader before the bootstrapModule/bootstrapApplication call
// defineCustomElements(window);
// if (environment.production) {
//   enableProdMode();
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
