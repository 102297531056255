import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NoInternetConnectionComponent } from './shared/components/status-page/component/no-internet-connection/no-internet-connection.component';
import { ErrorsHandler, InterceptedHttp } from './shared/interceptor';
import { UserService } from './shared/services/user.service';
import { VersionUpgradeComponent } from './shared/components/status-page/component/version-upgrade/version-upgrade-dialog-popup.component';

export function createSessionServiceFactory(userService: UserService) {
  return () => userService.getSessionDetails();
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NoInternetConnectionComponent,
    VersionUpgradeComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
      extendedTimeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      enableHtml: true
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [{
    provide: ErrorHandler,
    useClass: ErrorsHandler,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptedHttp,
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: createSessionServiceFactory,
    deps: [UserService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
