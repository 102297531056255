<div class="no-internet-connection">
    <div class="no-internet-connection-part">
        <div class="no-internet-connection-icon">
            <img src="assets/img/internet-connection-icon.png" />
        </div>
        <div class="no-internet-connection-text">
            <h2>No Internet Connection</h2>
            <p>Please check your internet settings and try again.</p>
        </div>
    </div>
</div>