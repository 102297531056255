import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorsHandler implements ErrorHandler {
    constructor() { }

    handleError(error: Error | HttpErrorResponse) {
        console.log(error)
        if (error instanceof HttpErrorResponse) {
            // SERVER ERROR HANDLING
        } else {
            // CLIENT ERROR HANDLING
        }
    }
}