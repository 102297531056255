import { ChangeDetectorRef, Component, EventEmitter, Input, Output, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ApplicationConfigurationService } from 'src/app/shared/services/application-configuration.service';
import { CommonMethodService } from 'src/app/shared/services/common-method.service';
import { PostalChannelService } from 'src/app/shared/services/postal.channel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version-upgrade',
  templateUrl: './version-upgrade.component.html',
  styleUrls: ['./version-upgrade.component.scss']
})
export class VersionUpgradeComponent {

  private postalMessageSubscription: Subscription;
  public FORMAPP_UPGRADE_MESSAGE = {
    title: 'Update Required',
    subtitle: 'To use the Adit Patient Forms app, please update to the latest version.',
    upgrade_link: {
      ios: environment.upgrade_link.ios,
      android: environment.upgrade_link.android
    },
    button_text: 'Update Now'
  }

  constructor(public applicationConfigurationService: ApplicationConfigurationService, private cdr: ChangeDetectorRef, private postalChannelService: PostalChannelService, private commonMethodService: CommonMethodService) {
  }

  ngOnInit() {
    this.updateAppDataAndVersion();
    this.postalMessageSubscription = this.postalChannelService.postalMessage$.subscribe(postalObj => {
      if (postalObj.channel == 'UPGRADE_APP_DATA_AVAILABLE') {
        this.updateAppDataAndVersion();
      }
    })
  }

  private updateAppDataAndVersion() {
    let upgrade_component = this.applicationConfigurationService.GET_APP_DATA('FORMAPP_UPDGRADE_DIALOG');;
    if (upgrade_component) {
      this.FORMAPP_UPGRADE_MESSAGE = upgrade_component;
      this.cdr.detectChanges();
    }
  }

  redirectToStore() {
    if (this.FORMAPP_UPGRADE_MESSAGE && this.FORMAPP_UPGRADE_MESSAGE.upgrade_link) {
      let platform = this.commonMethodService.getPlatform();
      if (platform === 'android' || platform === 'ios') {
        window.open(this.FORMAPP_UPGRADE_MESSAGE.upgrade_link[platform], '_blank');
      }
    }
  }

  ngOnDestroy() {
    if (this.postalMessageSubscription) {
      this.postalMessageSubscription.unsubscribe();
    }
  }
}
